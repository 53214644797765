<template>
  <v-container fluid class="pa-0">
    <v-carousel cycle hide-delimiter-background height="33vh">
      <v-carousel-item v-for="(carouselItem, i) in carouselItems" :key="i">
        <v-card
          :image="carouselItem.src"
          class="mx-auto fill-height fill-width d-flex align-center justify-center"
          tile
        >
          <v-row
            class="fill-height d-flex align-center justify-center flex-column"
          >
            <div class="text-h4 text-white font-weight-bold text-shadow">
              {{ carouselItem.title }}
            </div>
            <v-btn
              rounded
              variant="tonal"
              append-icon="mdi-chevron-right"
              color="white"
              class="mt-4"
              :to="carouselItem.buttonLink"
            >
              {{ carouselItem.buttonTitle }}
            </v-btn>
          </v-row>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <v-row class="ma-8">
      <v-col cols="12" md="6">
        <v-img
          :src="require('@/assets/img/scott_home.jpeg')"
          aspect-ratio="1"
          cover
        ></v-img>
      </v-col>
      <v-col cols="12" md="6">
        <div class="text-h3">Hi, I'm Scott!</div>
        <div class="text-h4">Welcome to my website</div>
        <p class="mt-3">
          I'm a software engineering graduate of Dublin City University
          currently living in Ireland. Outside of tech, programming and
          development, my interests lie in aviation and photography.
        </p>
        <p class="mt-3">
          On this site, you can explore my up-to-date CV, take a look at some
          projects I've worked on both inside and out of university, and view
          some of my favourite photos I've captured over the years.
        </p>
        <p class="mt-3">
          Feel free to explore my website and learn more about me!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    colors: ["indigo", "warning", "pink darken-2"],
    slides: ["Résumé / CV", "My Projects", "Photography"],
    carouselItems: [
      {
        title: "Résumé / CV",
        buttonTitle: "View CV",
        src: require("@/assets/img/pic_2.jpeg"),
        buttonLink: "/resume",
      },
      {
        title: "My Projects",
        buttonTitle: "View My Projects",
        src: require("@/assets/img/pic_3.jpeg"),
        buttonLink: "/projects",
      },
      {
        title: "Photography",
        buttonTitle: "View Photos",
        src: require("@/assets/img/pic_1.jpeg"),
        buttonLink: "/photos",
      },
    ],
  }),
};
</script>

<style scoped>
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
