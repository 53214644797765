<template>
  <v-app>
    <v-app-bar v-model="showAppBar" flat class="bg-deep-purple">
      <v-container fluid>
        <v-row no-gutters align="center">
          <v-col cols="2">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          </v-col>
          <v-col cols="8">
            <v-app-bar-title class="text-center">
              {{ $route.name }}
            </v-app-bar-title>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="bg-deep-purple"
      theme="dark"
      width="300"
    >
      <v-card class="mx-auto pb-4 bg-deep-purple" flat>
        <v-card-item>
          <v-row align="center" no-gutters>
            <v-col class="text-center py-4">
              <v-avatar class="elevation-4 no-border" size="125">
                <img
                  :src="require('@/assets/img/profile.jpeg')"
                  alt="Profile"
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col class="text-h4 text-center"> Scott Brady </v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle>
          <v-row align="center" no-gutters>
            <v-col class="text-center"> CS Graduate @ DCU </v-col>
          </v-row>
        </v-card-subtitle>
        <!-- <v-card-text>
          <v-row align="center" no-gutters>
            <v-col class="text-center">
              <a
                class="white--text text-decoration-none"
                href="mailto:hello@scottbrady.ie"
                >hello@scottbrady.ie</a
              >
            </v-col>
          </v-row>
        </v-card-text> -->
      </v-card>
      <v-divider
        :thickness="2"
        class="border-opacity-50 mx-3 mt-2"
        color="white"
      ></v-divider>
      <v-list density="default" lines="two">
        <v-list-item
          v-for="(item, i) in listItems"
          :key="i"
          :value="item"
          link
          :to="item.link"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-footer class="bg-green-accent-3 text-center d-flex flex-column">
          <div>
            <v-btn
              v-for="button in socialButtons"
              :key="button"
              :icon="button.icon"
              class="mx-4"
              variant="text"
              :href="button.link"
              target="_blank"
            ></v-btn>
          </div>
          <v-divider></v-divider>

          <div>
            &copy;
            {{ new Date().getFullYear() }} Scott Brady • All Rights Reserved
          </div>
        </v-footer>
      </template>
      <v-btn
        v-if="!showAppBar"
        class="toggle-drawer-button"
        icon
        @click="drawer = !drawer"
        color="green-accent-3"
      >
        <v-icon :class="{ 'offset-right': !drawer }">
          {{ drawer ? "mdi-chevron-left" : "mdi-chevron-right" }}
        </v-icon>
      </v-btn>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: null,
    appBar: null,
    socialButtons: [
      {
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/in/scottbrady76/",
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/scottbrady_2001/",
      },
      {
        icon: "mdi-email",
        link: "mailto:hello@scottbrady.ie",
      },
    ],

    listItems: [
      { text: "Home", icon: "mdi-home", link: "/" },
      { text: "Résumé / CV", icon: "mdi-file-account", link: "/resume" },
      { text: "My Projects", icon: "mdi-hammer", link: "/projects" },
      { text: "Photography", icon: "mdi-camera", link: "/photography" },
    ],
  }),
  watch: {
    // "$vuetify.breakpoint.smAndUp": {
    //   immediate: true,
    //   handler: function (newValue) {
    //     console.log(newValue);
    //     if (newValue !== undefined) {
    //       this.showAppBar = !newValue;
    //     }
    //   },
    // },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          (to.meta.title || to.name) + " | Scott Brady's Website";
      },
    },
  },
  mounted() {
    this.showAppBar = !this.drawer;
  },
  computed: {
    listDensity() {
      return this.$vuetify.breakpoint.smAndUp ? "default" : "compact";
    },
    listLines() {
      return this.$vuetify.breakpoint.smAndUp ? "two" : "one";
    },
  },
};
</script>

<style scoped>
.no-border {
  border: none !important;
}
.toggle-drawer-button {
  position: absolute;
  right: -28px; /* Half the width of the button */
  top: 50%;
  transform: translateY(-50%);
}
.toggle-drawer-button:hover {
  transform: translateY(-50%) scale(1.2);
}
.offset-right {
  transform: translateX(10px);
}
</style>
