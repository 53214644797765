<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" style="font-size: 80px"> &#128512; </v-col>
      <v-col cols="12">
        <router-link to="/">
          <v-btn color="primary">Go to Home</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
