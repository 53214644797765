<template>
  <v-banner color="deep-purple-accent-4" icon="mdi-file-pdf-box" lines="one">
    <v-banner-text>Résume / CV PDF</v-banner-text>

    <template v-slot:actions>
      <v-btn
        href="/docs/Scott_Brady_CV_1P.pdf"
        target="_blank"
        rel="noopener noreferrer"
        download
        icon="mdi-download"
      ></v-btn>
      <v-btn
        icon="mdi-eye"
        href="/docs/Scott_Brady_CV_1P.pdf"
        target="_blank"
      ></v-btn>
    </template>
  </v-banner>
  <v-container>
    <v-timeline side="end" truncate-line="start">
      <v-timeline-item hide-dot :class="'text-h6 text-end'">
        <template v-slot:opposite>Professional Experience</template>
      </v-timeline-item>
      <v-timeline-item dot-color="#01857C" fill-dot icon="mdi-needle">
        <template v-slot:opposite> Sept 2024 - Present </template>
        <div class="text-h6">Assoc. Engineering Specialist</div>
        <p class="text-medium-emphasis">MSD Biotech</p></v-timeline-item
      >

      <v-timeline-item dot-color="#0597D6" fill-dot icon="mdi-cash-multiple">
        <template v-slot:opposite>
          <div class="text-end">March 2023 - Sept 2023</div>
        </template>
        <div class="text-h6">ETF Analytics Developer</div>
        <p class="text-medium-emphasis pb-2">Susquehanna International Group</p>
        <p>
          Developed and maintained full-stack Python / Vue.js-based web apps
          used across EFT desks.
        </p>
      </v-timeline-item>
      <v-timeline-item hide-dot :class="'text-h6'">
        <template v-slot:opposite> Education </template>
      </v-timeline-item>
      <v-timeline-item dot-color="#003C66" fill-dot icon="mdi-school">
        <template v-slot:opposite> 2020 - 2024 </template>
        <div class="text-h6">BSc in Computer Applications (CASE)</div>
        <p class="text-medium-emphasis pb-2">Dublin City University</p>
        <p>Graduated with First Class Honours</p>
      </v-timeline-item>

      <v-timeline-item dot-color="#973930" fill-dot icon="mdi-chair-school">
        <template v-slot:opposite> 2014 - 2020 </template>
        <div class="text-h6">Leaving Certificate</div>
        <p class="text-medium-emphasis pb-2">
          St Mary's Diocesan School, Drogheda
        </p>
        <p>590 Leaving Certificate Points</p>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style scoped>
.no-border {
  border: none !important;
}
</style>
